import en from 'nanoid-good/locale/en';
import nanoid from 'nanoid-good';
import debounce from 'lodash.debounce';
import config from './config';

const { env, kibanaApi } = config();
const getUuid = nanoid(en);

// kibana event used for js runtime errors
const KIBANA_ERROR = 'error';
// kibana event used for outgoing service calls
const KIBANA_SERVICE = 'measure';

let batch = [];

const stringifyBatch = () => {
  const batchString = JSON.stringify(batch);
  batch = [];

  return batchString;
};

const flush = async () => {
  if (batch.length > 0) {
    window.fetch(kibanaApi, {
      method: 'POST',
      body: stringifyBatch(),
    });
  }
};

const postLogsSoon = debounce(flush, 500, { maxWait: 3000 });

const objFromError = (err) => {
  if (!(err instanceof Error)) {
    return err;
  }

  const obj = {
    name: err.stack,
    message: err.message,
  };

  if (err.stack) {
    obj.stack = err.stack;
  }

  return obj;
};

const buildExtras = (extras) => {
  const {
    code = '',
    error = {},
    requestId = '',
  } = extras;

  return {
    ...(code !== '' && { code }),
    ...(requestId !== '' && { requestId }),
    ...(!!error && Object.keys(error).length && { error }),
  };
};

export const initialize = ({
  component,
  sessionID,
}) => {
  const defaultLogData = () => ({
    env,
    uri: window.location.href,
    page: window.location.pathname,
    appname: 'my-account-web',
    time: Date.now(),
    uuid: getUuid(),
    MAW_sessionID: sessionID,
    component,
  });

  const formatLog = (event, details = {}) => {
    const { error, guid = '', ...info } = details;
    const logError = objFromError(error);
    return {
      event,
      data: { message: JSON.stringify({ ...info, error: logError }) },
      guid,
      ...defaultLogData(),
    };
  };

  const formatServiceCall = (details) => {
    const {
      duration = 0,
      status,
      url = '',
      method = '',
      message = '',
      guid = '',
      ...extras
    } = details;
    return {
      event: KIBANA_SERVICE,
      data: {
        request: {
          method,
          data: '',
        },
        response: {
          text: message,
          status,
        },
        url,
        message: JSON.stringify(buildExtras(extras)),
      },
      duration,
      guid,
      ...defaultLogData(),
    };
  };

  const formatServiceError = (details) => {
    const {
      error: {
        data: {
          error = {},
          message,
          ...extraData
        } = {},
        status,
      } = {},
    } = details;

    return formatServiceCall({
      ...details,
      status,
      message: error.errorMessage || message,
      error,
      ...extraData,
    });
  };

  const log = (event, details = {}) => {
    batch.push(formatLog(event, details));
    postLogsSoon();
  };

  const errorLog = (details = {}) => {
    log(KIBANA_ERROR, details);
  };

  const serviceLog = (details = {}) => {
    if (details.event !== 'ajax_response' && details.event !== 'ajax_response_error') return;

    const data = details.event === 'ajax_response' ? formatServiceCall(details) : formatServiceError(details);

    batch.push(data);
    postLogsSoon();
  };

  return {
    log,
    errorLog,
    serviceLog,
  };
};

export default {
  initialize,
};
