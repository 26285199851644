import throttle from 'lodash.throttle';

const LEGACY_OAUTH_INVALID_REQUEST = 'SH401.56.19';
const OAUTH_INVALID_REQUEST = 'SH401.55.19';

const LEGACY_OAUTH_ACCESS_DENIED_INVALID_TOKEN = 'SH401.56.18';
const OAUTH_ACCESS_DENIED_INVALID_TOKEN = 'SH401.55.18';

export const getCodeFromError = (error = {}) => {
  const { code } = error.data || error.body || error;
  return code || false;
};

export const shouldForceAuth = (error = {}) => {
  const code = getCodeFromError(error);

  return Boolean(
    (error.data && error.data.error === 'unauthenticated')
    || (error.data && error.data.logged_in_within_limit) === false
    || code === LEGACY_OAUTH_INVALID_REQUEST
    || code === OAUTH_INVALID_REQUEST
  );
};

export const shouldPassiveAuth = (error = {}) => {
  const code = getCodeFromError(error);

  return Boolean(
    code === LEGACY_OAUTH_ACCESS_DENIED_INVALID_TOKEN
    || code === OAUTH_ACCESS_DENIED_INVALID_TOKEN
    || (error.data && typeof error.data.body === 'string' && error.data.body.trim() === 'expired timestamp') // Codebig
    || (error.data && typeof error.data === 'string' && /expired timestamp\n$/.test(error.data)) // Unknown source
    || (error.data && Array.isArray(error.data) && error.data[0] === '{"error":"unauthenticated"}') // Codebig Staging
    || (error.data && error.data.error === 'unauthenticated') // Codebig Prod
  );
};

const passiveAuthTimeout = 15e3; // 15 seconds

export const passiveAuth = throttle(
  ({ basePath = '' } = {}) => (
    new Promise((resolve, reject) => {
      let iframeStatus;

      const iframe = window.document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.onload = () => {
        iframeStatus = 'success';
        resolve();
      };
      iframe.onerror = () => {
        iframeStatus = 'fail';
        reject();
      };
      iframe.src = `${basePath}/oauth/passive_connect/?continue=${encodeURIComponent('https://customer.xfinity.com/assets/close.svg')}`;
      window.document.body.appendChild(iframe);

      // Remove iframe and reset data after requests have been reloaded
      setTimeout(() => {
        window.document.body.removeChild(iframe);
        if (!iframeStatus) { // status was never updated, iFrame is stuck, let's reject it
          reject(new Error('passiveAuth timeout'));
        }
      }, passiveAuthTimeout);
    })
  ),
  passiveAuthTimeout, // throttle duration
  { leading: true, trailing: false }, // Execute the function immediately, do not execute at the end of the throttle period
);

export const forceAuth = ({ basePath = '' } = {}) => {
  const {
    href,
    origin,
  } = window.location;
  // The current location we want to redirect the client back to after they enter their password.
  const afterAuthContinueURL = encodeURIComponent(href);
  // Send the browser to the CIMA login screen after we destroy the MAW session.
  const forceConnectUrl = encodeURIComponent(`${origin}${basePath}/oauth/force_connect?continue=${afterAuthContinueURL}`);

  // Send the browser to the oauth/disconnect endpoint to destroy the MAW session and isAuth cookies.
  window.location.replace(`${basePath}/oauth/disconnect?continue=${forceConnectUrl}`);
};

const refreshAuthTimeout = 2e4; // 20 seconds

export const triggerRefreshAuthEvent = throttle(
  () => {
    let rejectAuthTimeout;
    const authPromise = new Promise((resolve, reject) => {
      let event;
      if (typeof Event === 'function') {
        event = new Event('refreshAuth');
      } else {
        // IE event support.
        event = document.createEvent('Event');
        event.initEvent('refreshAuth', true, true);
      }
      event.authPromise = { resolve, reject };
      window.dispatchEvent(event);
      // If the auth refresh does not resolve within 20 seconds, then assume it's stuck and reject it.
      rejectAuthTimeout = setTimeout(() => reject(new Error('refreshAuth timeout')), refreshAuthTimeout);
    });
    // When the authPromise is complete, cancel the rejection timeout to be safe.
    authPromise.finally(() => clearTimeout(rejectAuthTimeout));
    return authPromise;
  },
  refreshAuthTimeout, // throttle duration
  { leading: true, trailing: false }, // Execute the function immediately, do not execute at the end of the throttle period
);

export const ErrorHandler = ({ onRefreshAuth = triggerRefreshAuthEvent, onForceAuth } = {}) => (
  async ({ request, error, logger, basePath = '', retry }) => {
    if (!request.passiveRetry // Check if the request has already been retried.
      && !request.url.startsWith(`${basePath}/apis/macaroon`) // Don't retry the macaroon because auth refresh would trigger another macaroon call.
      && shouldPassiveAuth(error)
    ) {
      logger.log('refresh_login', error);
      request.passiveRetry = true;
      if (typeof onRefreshAuth === 'function') {
        await onRefreshAuth();
      }
      return retry(request);
    } else if (shouldForceAuth(error) || shouldPassiveAuth(error)) {
      logger.log('force_login', error);
      error.forceAuthentication = true;
      typeof onForceAuth === 'function'
      ? onForceAuth(error, request)
      : forceAuth({ basePath });
    }

    throw error;
  }
);
