import common from './common';
import local from './local';
import dev from './dev';
import staging from './staging';
import prod from './prod';
import preProd from './pre-prod';

export default function () {
  function getEnvFromUrl() {
    return [
      [/\b(customer|payments)\b\.dev/, 'dev'],
      [/\b(customer|payments)\b\.xfinity/, 'prod'],
      [/\b(customer|payments)\b\.pre-prod/, 'pre-prod'],
      [/\b(customer|payments)\b\.staging/, 'staging'],
      [/\b(customer|payments)\b\.qa/, 'qa'],
    ].reduce((str, environment) => (environment[0].test(window.location && window.location.host) ? environment[1] : str), 'local');
  }

  let config = {};
  const env = getEnvFromUrl();

  switch (env) {
    case 'local':
      config = local;
      break;
    case 'dev':
      config = dev;
      break;
    case 'staging':
      config = staging;
      break;
    case 'pre-prod':
      config = preProd;
      break;
    case 'prod':
      config = prod;
      break;
    default:
      config = common;
  }

  return config;
}
