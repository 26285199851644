import Performance from './performance';

/**
 * sanitizes the url
 * @param {string} url url
 */
const sanitizeUrl = (url) => {
  return url.replace(/(\d+([-_]?\d+)+)/g, '*')
    .replace(/\/apis\//, 'api-');
}

/**
 * create a "unique ID" from a request object
 * for performance tracking
 * @param {sting} page webpage path
 * @param {string} url url
 */
const requestUniqueId = ({ url, page }) => {
  return `${page}--${sanitizeUrl(url)}`;
}

export default function(log) {
  const responses = {};
  const performance = new Performance(log);
  return {
    /**
     * checks to see if response has already been marked for this page,
     * if not mark the response
     * @param {object} response response object
     */
    logResponse(response = {}, request = {}) {
      const {
        options: { method },
        page,
        url,
      } = request;
      const { status, data = {} } = response;
      const uniqueId = requestUniqueId(request);

      if (!responses[uniqueId]) {
        responses[uniqueId] = true;

        window.setTimeout(() => {
          delete responses[uniqueId];
        }, 5000);

        let logData = {
          event: 'ajax_response',
          method,
          status,
          page,
          url: sanitizeUrl(url)
        };

        if (status >= 400) {
          Object.assign(logData, data)
          logData.event = 'ajax_response_error'
        }
        performance.end(url, logData);
      }
    },
    /**
     * start perfomance tracking for a request
     * @params {object} request request object
     * @return {object} request
     */
    logRequest(request = {}) {
      let { url } = request;
      performance.start(sanitizeUrl(url));
      return request;
    },

    /**
     * log the response error
     * @params {object} error object
     * @return {object} response
     */
    logError(error = {}, request = {}) {
      const { options: { method } = {}, status, page, url } = request;
      const duration = performance.getDuration(sanitizeUrl(url));
      log('ajax_response_error', {
        event: 'ajax_response_error',
        name: error.name,
        message: error.message,
        error: error,
        method,
        status,
        page,
        url: sanitizeUrl(url),
        duration
      });
    }
  }
}
