const performance = window.performance;
let performanceTimings = [
  ['perf-network-latency', 'fetchStart', 'responseEnd'],
  ['perf-processing', 'responseEnd', 'loadEventEnd'],
  ['perf-full-request', 'navigationStart', 'loadEventEnd']
];
let marks = {};

/**
 * collect performance timings and send them off
 */
function collectTimings(log) {
  performanceTimings.map(measure)
    .filter(m => m && m.name)
    .forEach(data => send(data)(log));
}

/**
 *
 * measure the start and end marks and return the object
 * @param {object} timing array
 * @return {object} mark object
 */
function measure(timing) {
  let measure;

  try {
    performance.measure(...timing);
    measure = performance.getEntriesByName(timing[0])[0];
    measure = measure.toJSON ? measure.toJSON() : measure;
  } catch (e) {}

  return measure;
}

/**
 * trigger a log event
 * @param {object} timing
 */
function send(data) {
  data.event = data.event || 'metrics';
  return function(log) {
    if (typeof log === 'function') {
      log(data.event, data);
    }
  }
}

export default class Performance {
  constructor(log) {
    this.log = log;
    window.addEventListener('load',
      window.setTimeout.bind(null, () => { collectTimings(this.log) }, 0)
    );
  }
  /**
   * mark the start of an event
   * @param {string} name mark name
   */
  start(name) {
    let ref = marks[name];

    if (!ref) {
      marks[name] = true;
      try {
        performance.mark(`${name}-start`);
      } catch (e) {}
    }
  }

  /**
   * mark the end of an event
   * @param {string} name mark name
   */
  end(name, data = {}) {
    let ref = marks[name];
    let endName = `${name}-end`;

    if (ref) {
      try {
        performance.mark(endName);
      } catch (e) {}
      let entry = measure([name, `${name}-start`, endName]);

      if (entry) {
        const { duration, name, startTime } = entry;
        Object.assign(data, {
          duration,
          name,
          startTime
        });
        send(data)(this.log);
        delete marks[name];
      }
    }
  }

  /**
   * get duration without sending log. Used in logError.
   * @param {string} name mark name
   * @return {number} duration of the request
   */
  getDuration(name) {
    const ref = marks[name];
    const endName = `${name}-end`;

    if (ref) {
      try {
        performance.mark(endName);
      } catch (e) {}
      const entry = measure([name, `${name}-start`, endName]);

      if (entry) {
        const { duration } = entry;
        delete marks[name];
        return duration;
      }
    }
    return 0;
  }
}
