import Cookies from 'js-cookie';
import * as qs from 'query-string';

import LogHelperFactory from './logging';

let mockstate;
let resp_type = qs.parse(window.location.search).resp_type;
resp_type = resp_type === undefined ? undefined : parseInt(resp_type);
const urlParams = qs.parse(window.location.search);

const injectMockStates = (url) => {
  mockstate = urlParams.mockstate;
  let mockUrl = url;
  if (mockstate) {
    let param = `mockstate=${encodeURI(mockstate)}`;
    if (resp_type !== undefined) {
      param = `${param}&resp_type=${resp_type}`;
    }
    if (url.indexOf('?') !== -1) {
      param = `&${param}`;
    } else {
      param = `?${param}`;
    }
    mockUrl += param;
  }
  return mockUrl;
};

async function getJSON(response) {
  const { status } = response;
  if (status === 204) return {};
  const responseForError = response.clone();
  try {
    const json = await response.json();
    return json;
  } catch (error) {
    const body = await responseForError.text();
    throw { status, data: { body } };
  }
}

let inFlight = 0;
const scheduleAdvanceMock = (after) => {
  inFlight++;
  after.finally(result => {
    inFlight--;
    if (inFlight === 0) {
      resp_type++;
    }
  });
};

const ClientFactory = (factoryOptions = {}) => {
  const defaultOptions = { logger: console, basePath: '' };
  const {
    logger,
    kibanaServiceLog,
    basePath,
    onError
  } = {
    ...defaultOptions,
    ...factoryOptions
  };
  const defaultLogData = {
    segment: Cookies.get('xf_seg') || '',
    tid: Cookies.get('xf_tid') || ''
  };
  const log = (name, data, ...args) => {
    logger.log(name, { ...defaultLogData, ...data }, ...args);
    if (kibanaServiceLog !== undefined) kibanaServiceLog(data);
  };
  const {
    logRequest,
    logResponse,
    logError
  } = LogHelperFactory(log);

  if (logger === console) {
    console.warn('No logger passed in maw-fetch client configuration.');
  }

  const defaultHeaders = {};
  const setHeaders = (headers) => {
    Object.assign(defaultHeaders, headers);
    return { ...defaultHeaders };
  };

  const requestFetch = (url, options = {}) => {
    const {
      headers,
      ...requestOptions
    } = options;

    // prepend basePath ONLY if url path is relative
    url = /^\//.test(url) ? `${basePath}${url}` : url;

    const modifiedUrl = injectMockStates(url);
    const modifiedOptions = {
      method: 'get',
      ...requestOptions,
      credentials: 'same-origin',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json; v=2',
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
        ...defaultHeaders,
        ...headers,
      })
    };

    const requestObj = {
      url: modifiedUrl,
      options: modifiedOptions,
      page: window.location.pathname,
    };

    const tryFetch = async (request) => {
      try {
        logRequest(request);
        const response = await fetch(request.url, request.options);
        const { ok, status } = response;
        const json = getJSON(response);
        if (mockstate && resp_type !== undefined) {
          const method = modifiedOptions.method.toLowerCase();
          if (method === 'post' || method === 'put' || method === 'delete') {
            scheduleAdvanceMock(json);
          }
        }
        const data = await json;
        const responseObj = { data, status };
        logResponse(responseObj, request);
        if (ok && status < 400) {
          return data;
        }
        throw responseObj;
      } catch (error) {
        logError(error, request);
        if (typeof onError === 'function') {
          return onError({ request, error, logger, basePath, retry: tryFetch });
        }

        throw error;
      }
    }

    return tryFetch(requestObj);
  };

  return {
    fetch: requestFetch,
    setHeaders,
  };
};

export default ClientFactory;
